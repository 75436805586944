<template>
  <div class="home">
    <div class="head">
      <span>日照之游 🌊</span>
      <h3>2022.07.24日Start ～</h3>
      <div class="times">
        <span>已经完美结束啦～</span>
      </div>
    </div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Tips 🍪</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="watchPhoto"
          >相册</el-button
        >
      </div>
      <div class="con">
        <List />
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  components: {
    List: () => import("@/components/List"),
  },
  data() {
    return {
      DAY: "",
      HOUR: "",
      MIN: "",
      SEC: "",
    };
  },
  methods: {
    watchPhoto() {
      this.$router.push({ name: "Photos" });
    },
  },
};
</script>
<style>
.el-card__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.home {
  margin: auto;
  width: 100%;
  max-width: 960px;

  .head {
    height: 166px;
  }
  .times {
    float: right;
    font-size: 16px;
    span {
      font-size: 24px;
      font-weight: bolder;
    }
  }
}
</style>
