/*
 * @Author: Han
 * @Date: 2022-01-12 15:37:21
 * @LastEditors: Han
 * @LastEditTime: 2022-01-12 17:17:48
 * @FilePath: \饼\bing\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementArr from '@/plugins/element'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Object.values(elementArr).forEach(itm => {
  Vue.use(itm)
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')