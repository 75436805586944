<template>
  <div id="vue">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>
<style>
::-webkit-scrollbar {
  width: 0;
}

@font-face {
  font-family: "Han Font";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/font/font.woff2");
  src: url("./assets/font/font.woff2") format("woff2");
}

body {
  font-family: "Han Font";
  user-select: none;
  -webkit-user-select: none;
}
</style>