/*
 * @Author: Han
 * @Date: 2021-11-09 15:55:55
 * @LastEditors: Han
 * @LastEditTime: 2022-01-12 19:14:17
 * @FilePath: \饼\bing\src\plugins\element.js
 */

import {
  Popconfirm,
  Collapse,
  CollapseItem,
  Card,
  Button,
  Drawer,
  Input,
  Image,
} from 'element-ui';

export default {
  Popconfirm,
  Collapse,
  CollapseItem,
  Card,
  Button,
  Drawer,
  Input,
  Image,
};
